import React from 'react';
import {
  Avatar,
  Button,
  Flex,
  Image,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import {
  CurrencyDollarSimple,
  DownloadSimple,
  FileLock,
  Lock,
  NotePencil,
  SignOut,
  User,
} from 'phosphor-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { MdDashboard, MdOutlineInsertPageBreak } from 'react-icons/md';
import { BsFiletypeXml } from 'react-icons/bs';
import { FaFileInvoiceDollar, FaNotEqual } from 'react-icons/fa6';
import { RiFileList3Line } from 'react-icons/ri';
import { TbReportMoney } from 'react-icons/tb';
import { useAuth } from '../../providers/auth';
import logo from '../../assets/images/logo_white.png';
import { ResponsiveMenu } from '../sidebar/ResponsiveMenu';

const Header = () => {
  const { Logout } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  const isSubRouteSupervisor =
    location.pathname.startsWith('/modulesHome/supervisor') &&
    location.pathname !== '/modulesHome/supervisor';

  const OpenRoutesSupervisor = [
    {
      name: 'ANÁLISE DE IMPOSTOS',
      path: '/modulesHome/supervisor/sub',
      icon: <RiFileList3Line color="#008CCC" className="size-4" />,
      bgColor: '#DFF3FF',
    },
    {
      name: 'ANÁLISE REGIME TRIBUTÁRIO EMPRESAS',
      path: '/modulesHome/supervisor/taxRegime',
      icon: <TbReportMoney color="#F2994A" className="size-4" />,
      bgColor: '#FFE1C6',
    },
    {
      name: 'GUIAS SIMPLES NACIONAL',
      path: '/modulesHome/supervisor/simples',
      icon: <CurrencyDollarSimple color="#4CAF50" className="size-4" />,
      bgColor: '#FFEB99',
    },
    {
      name: 'REGIME TRIBUTÁRIO SÓCIOS',
      path: '/modulesHome/supervisor/partner',
      icon: <User color="#37474F" className="size-4" />,
      bgColor: '#CFD8DC',
    },
  ];

  const isSubRoute =
    location.pathname.startsWith('/modulesHome/integration') &&
    location.pathname !== '/modulesHome/integration';

  const OpenRoutes = [
    {
      name: 'Dashboard',
      path: '/modulesHome/integration/dashboard',
      icon: <MdDashboard color="#008CCC" className="size-4" />,
      bgColor: '#7AD8FF',
    },
    {
      name: 'Quebra de Sequência',
      path: '/modulesHome/integration/sequenceBreak',
      icon: <MdOutlineInsertPageBreak color="#008CCC" className="size-4" />,
      bgColor: '#DFF3FF',
    },
    {
      name: 'Análise do XML NFS-e',
      path: '/modulesHome/integration/xmlAnalysis',
      icon: <BsFiletypeXml color="#F2994A" className="size-4" />,
      bgColor: '#FFE1C6',
    },
    {
      name: 'Análise de Faturamento',
      path: '/modulesHome/integration/billingAnalysis',
      icon: <FaFileInvoiceDollar color="#219653" className="size-4" />,
      bgColor: '#ABEDC6',
    },
    {
      name: 'Dominio X SIEG',
      path: '/modulesHome/integration/apixsieg',
      icon: <FaNotEqual color="#ff4e2a" className="size-4" />,
      bgColor: '#fc6c4f',
    },
    {
      name: 'Importações Diárias',
      path: '/modulesHome/integration/dailyImports',
      icon: <DownloadSimple color="#0077B6" className="size-4" />,
      bgColor: '#D9F1F1',
    },
    {
      name: 'Lançamentos Manuais',
      path: '/modulesHome/integration/manualPostings',
      icon: <NotePencil color="#37474F" className="size-4" />,
      bgColor: '#CFD8DC',
    },
  ];
  return (
    <div className="fixed z-50 flex w-[100vw] items-center justify-between bg-primary p-2 pr-6">
      <div className="flex flex-col md:w-[100vw] md:flex-row ">
        <div className="flex items-center  lg:pr-14">
          <div className="block md:hidden">
            <ResponsiveMenu />
          </div>
          <Image src={logo} w="150px" />
        </div>
        {isSubRouteSupervisor ? (
          <div className="grid grid-cols-2 gap-2  md:w-2/3 md:grid-cols-2 md:pl-40 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4">
            {' '}
            {OpenRoutesSupervisor.map(item => (
              <div>
                {isSubRouteSupervisor ? (
                  // <h1>Rota atual: {location.pathname}</h1>
                  <Flex
                    className={`flex w-full gap-2 rounded-full ${item.path === location.pathname ? 'bg-[#196ABB8A]' : ''} cursor-pointer`}
                    onClick={() => navigate(item.path)}
                  >
                    <div>
                      <div
                        className={`flex items-center justify-center rounded-full bg-[${item.bgColor}] p-3 `}
                      >
                        {item.icon}
                      </div>
                    </div>
                    <div className="flex w-full flex-col justify-center gap-1">
                      <Text fontWeight="bold" fontSize="10px" color="white">
                        {item.name}
                      </Text>
                    </div>
                  </Flex>
                ) : undefined}
              </div>
            ))}
          </div>
        ) : undefined}
        {isSubRoute ? (
          <div className="grid grid-cols-2 gap-2 md:ml-20 md:w-2/3 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-7 2xl:grid-cols-7">
            {' '}
            {OpenRoutes.map(item => (
              <div>
                {isSubRoute ? (
                  // <h1>Rota atual: {location.pathname}</h1>
                  <Flex
                    className={`flex w-full gap-2 rounded-full ${item.path === location.pathname ? 'bg-[#196ABB8A]' : ''} cursor-pointer`}
                    onClick={() => navigate(item.path)}
                  >
                    <div>
                      <div
                        className={`flex items-center justify-center rounded-full bg-[${item.bgColor}] p-3 `}
                      >
                        {item.icon}
                      </div>
                    </div>
                    <div className="flex w-full flex-col justify-center gap-1">
                      <Text fontWeight="bold" fontSize="12px" color="white">
                        {item.name}
                      </Text>
                    </div>
                  </Flex>
                ) : undefined}
              </div>
            ))}
          </div>
        ) : undefined}
      </div>
      <Popover>
        <PopoverTrigger>
          <Button variant="unstyled">
            <Avatar
              size="sm"
              src="https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9"
            />
          </Button>
        </PopoverTrigger>
        <PopoverContent mr="12px" p="5px" boxShadow="0px 4px 6px rgba(0, 0, 0, 0.09)">
          <PopoverCloseButton />
          <PopoverBody>
            <div className="flex flex-col items-start">
              <Link to="/perfil">
                <Button variant="unstyled" p="6px" display="flex" alignItems="center" gap={2}>
                  <User size={20} weight="bold" />
                  <Text>Minha Conta</Text>
                </Button>
              </Link>

              <Link to="/seguranca">
                <Button variant="unstyled" p="6px" display="flex" alignItems="center" gap={2}>
                  <Lock size={20} weight="bold" />
                  <Text>Segurança</Text>
                </Button>
              </Link>

              <Link to="/sessoes">
                <Button variant="unstyled" p="6px" display="flex" alignItems="center" gap={2}>
                  <FileLock size={20} weight="bold" />
                  <Text>Sessões</Text>
                </Button>
              </Link>

              <PopoverHeader w="full" />

              <Button
                variant="unstyled"
                p="6px"
                display="flex"
                alignItems="center"
                gap={2}
                onClick={Logout}
              >
                <SignOut size={20} weight="bold" />
                <Text>Sair</Text>
              </Button>
            </div>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </div>
  );
};

export default Header;
