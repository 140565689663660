import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@chakra-ui/react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import { InputForm } from '../../inputs/forms/InputForm';
import { FormBox } from '../../formBox';
import { FormHeader } from '../../FormHeader';
import { IUser, useUsers } from '../../../hooks/useUsers';
import { IGroup, useGroups } from '../../../hooks/useGroups';
import { IData, IPagination } from '../../../types/shared';
import SelectInputForm from '../../inputs/forms/SelectInputForm';
import { useAuth } from '../../../providers/auth';
import { FormStatus } from '../../formStatus';

const schema = yup.object().shape({
  id: yup.number(),
  nome: yup.string().required('Este campo é obrigatório!'),
  email: yup.string().email('E-mail inválido').required('Este campo é obrigatório!'),
  grupo: yup.mixed<IGroup>().nullable().required('Este campo é obrigatório!'),
  grupo_id: yup.number(),
  ativo: yup.boolean(),
  is_sympla: yup.boolean(),
  phone: yup.string(),
  password: yup.string(),
  confirmPassword: yup.string().oneOf([yup.ref('password')], 'As senhas não são iguais'),
});

interface IForm {
  id?: number;
  nome: string;
  email: string;
  grupo: IGroup;
  grupo_id?: number;
  ativo?: boolean;
  is_sympla?: boolean;
  phone?: string;
  password?: string;
  confirmPassword?: string;
}

interface UsersFormProps {
  defaultValues?: IForm;
}

export default function UsersForm({ defaultValues }: UsersFormProps) {
  const navigate = useNavigate();
  const { createUser, updateUser } = useUsers();
  const { getGroups } = useGroups();
  const { getUser, user } = useAuth();

  const { mutateAsync } = useMutation({
    mutationFn: defaultValues ? updateUser : createUser,
  });

  const queryKey = ['GroupsSelect', { page: 1, size: 30, search: '' }];

  const { data, isFetching } = useQuery<IData<IGroup[]>>({
    queryKey,
    queryFn: getGroups,
  });

  const { items: groups } = data?.data ?? { items: [], ...({} as IPagination) };

  const formMethods = useForm<IForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      id: undefined,
      nome: defaultValues?.nome ?? '',
      email: defaultValues?.email ?? '',
      grupo: defaultValues?.grupo ?? undefined,
      grupo_id: undefined,
      ativo: true,
      is_sympla: defaultValues?.is_sympla ?? false,
      phone: '',
      password: '',
    },
  });

  const onSubmit = async (values: IForm) => {
    try {
      await mutateAsync({
        ...values,
        empresa_id: user?.empresa_id,
        grupo: _,
        grupo_id: values.grupo.id,
        id: defaultValues?.id ?? _,
        password: values?.password,
        confirmPassword: _,
      } as unknown as IUser);
      await getUser();
      toast.success(`Usuário ${defaultValues ? 'editado' : 'criado'} com sucesso!`, {
        position: 'top-right',
      });
      navigate('/configuracoes/usuarios', { replace: true });
    } catch (error: any) {
      toast.error(
        error?.response?.data?.detail ?? 'Erro interno no sistema, tente novamente mais tarde.',
        { position: 'top-right' },
      );
    }
  };

  return (
    <form onSubmit={formMethods.handleSubmit(onSubmit)} className="flex flex-col gap-4">
      <div className="grid grid-cols-1 gap-2 md:grid-cols-3">
        <FormHeader
          title="Usuário"
          subtitle="Dê acesso e gerencie os usuários ativos e vincule-os em grupos de trabalho."
        />

        <FormBox>
          <FormProvider {...formMethods}>
            <FormStatus
              name="is_sympla"
              title="Sympla ativo?"
              subtitle="Ative ou desative a visualização da página sympla para esse usuário"
            />

            <InputForm name="nome" label="Nome" placeholder="Nome do usuário" />
            <InputForm name="email" label="E-mail" placeholder="E-mail do usuário" />
            <InputForm
              name="phone"
              label="Telefone"
              placeholder="Telefone"
              mask="(99) 99999-9999"
            />
          </FormProvider>
        </FormBox>
      </div>
      <div className="grid grid-cols-1 gap-2 md:grid-cols-3">
        <FormHeader title="Permissões" subtitle="Gerencie as permissões do usuário" />

        <FormBox>
          <FormProvider {...formMethods}>
            <SelectInputForm<IGroup>
              name="grupo"
              label="Permissões gerais"
              options={groups}
              getOptionLabel={item => (item as IGroup).nome}
              getOptionValue={item => String((item as IGroup).id)}
              queryKeyName="GroupsSelect"
              getOptions={getGroups}
              isLoading={isFetching}
            />
          </FormProvider>
        </FormBox>
      </div>
      <div className="grid grid-cols-1 gap-2 md:grid-cols-3">
        <FormHeader title="Resetar senha" subtitle="Resetar senha de usuário" />

        <FormBox>
          <FormProvider {...formMethods}>
            <InputForm name="password" label="Senha" placeholder="Senha" type="password" />
            <InputForm
              name="confirmPassword"
              label="Confirmar senha"
              placeholder="Confirmar senha"
              type="password"
            />
          </FormProvider>
        </FormBox>
      </div>

      <div className="mt-3 border border-b-divider" />

      <div className="flex justify-between">
        <Button variant="outline" onClick={() => navigate(-1)}>
          Cancelar
        </Button>
        <Button type="submit">Salvar</Button>
      </div>
    </form>
  );
}
