/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Button, Flex, Spinner, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { useQuery } from '@tanstack/react-query';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { createSearchParams, useLocation, useSearchParams } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import { FaNotEqual } from 'react-icons/fa6';
import moment from 'moment';
import { IoCloseCircleOutline } from 'react-icons/io5';
import { CiCircleCheck, CiWarning } from 'react-icons/ci';
import { ReturnButton } from '../../../../components/buttons/ReturnButton';
import { InputForm } from '../../../../components/inputs/forms/InputForm';
import { FormBox } from '../../../../components/formBox';
import SelectInputForm from '../../../../components/inputs/forms/SelectInputForm';
import { IData, IPagination, ISelect } from '../../../../types/shared';
import { ICompany, useCompanies } from '../../../../hooks/useCompanies';
import { IClient } from '../../../../hooks/useClients';
import { formatCNPJ, getFormattedLabelString } from '../../../../utils/FormatFunctions';
import { DataTable } from '../../../../components/datatable';
import { DateToolbarFilter } from '../../../../components/DateToolbarFilter';

export function ModulesHomeIntegrationApiXSiegPage() {
  const { getCompanies, getCompaniesFilters, getAPIXSiegTotais } = useCompanies();
  const [showFilters, setShowFilters] = useState(false);
  const location = useLocation();

  // Obtém os parâmetros da query string
  const queryParams = new URLSearchParams(location.search);
  const searchParamStart = queryParams.get('date_start');
  const searchParamEnd = queryParams.get('date_end');
  const situacaoSearch = queryParams.get('situacao');

  const [filters, setFilters] = useSearchParams(
    createSearchParams({
      page: '1',
      size: '50',
      search: '',
      companyId: '',
      date_start: moment().startOf('month').format('YYYY-MM-DD'), // Convertendo para string
      date_end: moment().endOf('month').format('YYYY-MM-DD'), // Convertendo para string
      screen_data: 'api_sieg',
    }),
  );
  const toggleFilters = () => setShowFilters(prev => !prev);

  const columns: ColumnDef<ICompany>[] = [
    {
      accessorKey: 'external_id',
      header: 'ID',
    },
    {
      accessorKey: 'razao_social',
      header: 'Razão social',
    },
    {
      accessorKey: 'cnpj',
      header: 'CNPJ',
      cell: ({ row }) => {
        const value = row.original.cnpj;
        if (value !== null) {
          return formatCNPJ(value);
        }
        return '';
      },
    },
    {
      accessorKey: 'quantidade_notas',
      header: 'Quantidade de notas Domínio',
    },
    {
      accessorKey: 'quantidade_sieg',
      header: 'Quantidade de notas SIEG',
    },

    // {
    //   id: 'actions',
    //   cell: ({ row }) => {
    //     const company = row.original;
    //     const dateStart = filters.get('date_start');
    //     const dateEnd = filters.get('date_end');
    //     return (
    //       <div className="col-span-1">
    //         <Link
    //           to={{
    //             pathname: `${company.id}`,
    //             search: createSearchParams({
    //               date_start: dateStart || moment().subtract(6, 'days').format('YYYY-MM-DD'),
    //               date_end: dateEnd || moment().format('YYYY-MM-DD'),
    //             }).toString(),
    //           }}
    //           state={{ company: row.original }}
    //         >
    //           <Button
    //             variant="outline"
    //             h="fit-content"
    //             w="100%"
    //             cursor="pointer"
    //             alignItems="center"
    //             justifyContent="center"
    //             borderRadius="full"
    //             border="2px solid"
    //             borderColor="#718EBF"
    //             bg="white"
    //             p={2}
    //           >
    //             <Text className="text-sm" color="#718EBF">
    //               Ver notas
    //             </Text>
    //           </Button>
    //         </Link>
    //       </div>
    //     );
    //   },
    // },
    // {
    //   id: 'actions2',
    //   cell: ({ row }) => {
    //     const company = row.original;
    //     const dateStart = filters.get('date_start');
    //     const dateEnd = filters.get('date_end');
    //     return (
    //       <div className="col-span-1">
    //         <Link
    //           to={{
    //             pathname: `/modulesHome/integration/apixsieg/Sieg/${company.id}`,
    //             search: createSearchParams({
    //               date_start: dateStart || moment().subtract(6, 'days').format('YYYY-MM-DD'),
    //               date_end: dateEnd || moment().format('YYYY-MM-DD'),
    //             }).toString(),
    //           }}
    //           state={{ company: row.original }}
    //         >
    //           <Button
    //             variant="outline"
    //             h="fit-content"
    //             w="100%"
    //             cursor="pointer"
    //             alignItems="center"
    //             justifyContent="center"
    //             borderRadius="full"
    //             border="2px solid"
    //             borderColor="#718EBF"
    //             bg="white"
    //             p={2}
    //           >
    //             <Text className="text-sm" color="#718EBF">
    //               Ver notas sieg
    //             </Text>
    //           </Button>
    //         </Link>
    //       </div>
    //     );
    //   },
    // },
  ];

  const filtersFormMethods = useForm<{
    search: string;
    companyId: any;
    cliente: IClient;
    cidade: ISelect<string>;
    uf: ISelect<string>;
    grupo_de_empresas: ISelect<string>;
    tags: ISelect<string>;
    comercial: ISelect<string>;
    contabil: ISelect<string>;
    financeiro: ISelect<string>;
    contas_a_pagar: ISelect<string>;
    encerramento_anual: ISelect<string>;
    fiscal: ISelect<string>;
    imposto_de_renda: ISelect<string>;
    integracao_contabil: ISelect<string>;
    integracao_fiscal: ISelect<string>;
    pessoal: ISelect<string>;
    relacionamento_com_cliente: ISelect<string>;
    societario: ISelect<string>;
  }>({
    mode: 'onChange',
    defaultValues: {
      search: filters.get('search') ?? '',
    },
  });

  const debounced = useDebounceCallback(values => {
    const fields = {
      search: values.search,
      date_start: searchParamStart ?? filters.get('date_start') ?? '',
      date_end: searchParamEnd ?? filters.get('date_end') ?? '',
      situacao: situacaoSearch ?? filters.get('situacao') ?? '',
      id: values.companies?.external_id,
      cidade: values.cidade?.value,
      uf: values.uf?.value,
      grupo_de_empresas: values.grupo_de_empresas?.value,
      tags: values.tags?.value,
      comercial: values.comercial?.value,
      contabil: values.contabil?.value,
      financeiro: values.financeiro?.value,
      contas_a_pagar: values.contas_a_pagar?.value,
      encerramento_anual: values.encerramento_anual?.value,
      fiscal: values.fiscal?.value,
      imposto_de_renda: values.imposto_de_renda?.value,
      integracao_contabil: values.integracao_contabil?.value,
      integracao_fiscal: values.integracao_fiscal?.value,
      pessoal: values.pessoal?.value,
      relacionamento_com_cliente: values.relacionamento_com_cliente?.value,
      societario: values.societario?.value,
    };

    Object.entries(fields).forEach(([key, value]) => {
      if (value) filters.set(key, value);
      else filters.delete(key);
    });

    setFilters(filters);
  }, 500);

  useEffect(() => {
    const subscription = filtersFormMethods.watch(values => {
      debounced(values);
    });

    return () => subscription.unsubscribe();
  }, [filtersFormMethods.watch, searchParamStart, searchParamEnd, situacaoSearch]);

  const queryKeyCompaniesFilters = ['CompaniesFilters'];
  const { data: CompaniesFiltersData } = useQuery({
    queryKey: queryKeyCompaniesFilters,
    queryFn: getCompaniesFilters,
  });

  const CompaniesFilters = CompaniesFiltersData;

  const { data: dataCompaniesSelect, isFetching: isFetchingCompanySelect } = useQuery<
    IData<ICompany[]>
  >({
    queryKey: ['CompaniesSelect', { page: 1, size: 30, search: '' }],
    queryFn: getCompanies,
  });

  const { data: dataCompaniesList, isFetching: isFetchingCompanyList } = useQuery<
    IData<ICompany[]>
  >({
    queryKey: ['CompaniesList', Object.fromEntries([...Array.from(filters)])],
    queryFn: getCompanies,
  });

  const { items: companies } = dataCompaniesSelect?.data ?? {
    items: [],
    ...({} as IPagination),
  };

  const { items: companiesList, ...pagination } = dataCompaniesList?.data ?? {
    items: [],
    ...({} as IPagination),
  };

  const queryKeyQuebrasTotatais = [
    'getQuebrasTotais',
    Object.fromEntries([...Array.from(filters)]),
  ];

  const { data: quebrasTotais, isFetching: isFetchingQuebrasTotais } = useQuery<any>({
    queryKey: queryKeyQuebrasTotatais,
    queryFn: getAPIXSiegTotais,
  });

  return (
    <div className="flex w-full flex-col gap-2">
      <div className="flex flex-col items-center justify-between gap-3 py-5 lg:flex-row">
        <div className="flex content-start md:w-1/3 lg:content-center ">
          <ReturnButton previousPage="modulesHome/integration" />

          <div className="h-full">
            <div className="flex items-center justify-center rounded-full bg-[#fc6c4f] p-3">
              <FaNotEqual color="#ff4e2a" className="size-8" />
            </div>
          </div>
          <div className="flex w-full flex-col justify-center gap-1 pl-2">
            <Text fontWeight="bold" fontSize="16px">
              Dominio X SIEG
            </Text>
          </div>
        </div>
        <div className="mb-5 grid grid-cols-1 gap-3 md:grid-cols-3">
          {isFetchingQuebrasTotais ? (
            <div className="flex flex-col items-center gap-4">
              <Spinner size="lg" color="primary" />
              <span className="text-xl font-semibold">Carregando...</span>
            </div>
          ) : (
            <>
              <Flex
                className={`flex cursor-pointer items-center gap-3 rounded-3xl p-5 ${
                  filters.get('situacao') === 'ok' ? 'bg-[#DCFAF8]' : 'bg-body'
                }`}
                onClick={async () => {
                  const newFilters = new URLSearchParams(Object.fromEntries(filters));
                  if (filters.get('situacao') === 'ok') {
                    newFilters.delete('situacao'); // Remove o filtro se já estiver selecionado
                  }
                  if (filters.get('situacao') !== 'ok') {
                    newFilters.set('page', '1');
                    newFilters.set('situacao', 'ok');
                  }
                  if (!filters.get('situacao')) {
                    newFilters.set('situacao', 'ok');
                  }
                  await setFilters(newFilters);
                }}
              >
                <div className="flex h-14 w-16 items-center justify-center rounded-full bg-[#DCFAF8] p-3">
                  <CiCircleCheck color="#16DBCC" className="size-7" />
                </div>

                <div className="flex w-full flex-col ">
                  <Text fontSize="14px" color="#718EBF">
                    OK
                  </Text>
                  <Text fontSize="18px" fontWeight="bold">
                    {quebrasTotais?.ok}
                  </Text>
                </div>
              </Flex>
              <Flex
                className={`flex cursor-pointer items-center gap-3 rounded-3xl p-5 ${
                  filters.get('situacao') === 'erro' ? 'bg-[#FFE0EB]' : 'bg-body'
                }`}
                onClick={async () => {
                  const newFilters = new URLSearchParams(Object.fromEntries(filters));
                  if (filters.get('situacao') === 'erro') {
                    newFilters.delete('situacao'); // Remove o filtro se já estiver selecionado
                  }
                  if (filters.get('situacao') !== 'erro') {
                    newFilters.set('page', '1');
                    newFilters.set('situacao', 'erro');
                  }
                  if (!filters.get('situacao')) {
                    newFilters.set('situacao', 'erro');
                  }
                  await setFilters(newFilters);
                }}
              >
                <div className="flex h-14 w-16 items-center justify-center rounded-full bg-[#FFE0EB] p-3">
                  <IoCloseCircleOutline color="#FF82AC" className="size-7" />
                </div>

                <div className="flex w-full flex-col ">
                  <Text fontSize="14px" color="#718EBF">
                    Error
                  </Text>
                  <Text fontSize="18px" fontWeight="bold">
                    {quebrasTotais?.erro}
                  </Text>
                </div>
              </Flex>
              <Flex
                className={`flex cursor-pointer items-center gap-3 rounded-3xl p-5 ${
                  filters.get('situacao') === 'aviso' ? 'bg-[#FFFECA]' : 'bg-body'
                }`}
                onClick={async () => {
                  const newFilters = new URLSearchParams(Object.fromEntries(filters));
                  if (filters.get('situacao') === 'aviso') {
                    newFilters.delete('situacao'); // Remove o filtro se já estiver selecionado
                  }
                  if (filters.get('situacao') !== 'aviso') {
                    newFilters.set('page', '1');
                    newFilters.set('situacao', 'aviso');
                  }
                  if (!filters.get('situacao')) {
                    newFilters.set('situacao', 'aviso');
                  }
                  await setFilters(newFilters);
                }}
              >
                <div className="flex h-14 w-16 items-center justify-center rounded-full bg-[#FFFECA] p-3">
                  <CiWarning color="#FFBB38" className="size-7" />
                </div>

                <div className="flex w-full flex-col ">
                  <Text fontSize="14px" color="#718EBF">
                    Sob aviso
                  </Text>
                  <Text fontSize="18px" fontWeight="bold">
                    {quebrasTotais?.aviso}
                  </Text>
                </div>
              </Flex>
            </>
          )}
        </div>
      </div>

      <FormBox>
        <div className="flex flex-col gap-4  p-4">
          <div className="flex flex-col justify-between gap-2">
            <FormProvider {...filtersFormMethods}>
              <div className="flex flex-col justify-between gap-4 lg:flex-row">
                <div className="flex content-center items-center justify-center gap-3 pt-10">
                  <InputForm
                    name="search"
                    placeholder="Buscar por nome, cnpj, razão social"
                    boxShadow="0px 2px 4px #1E293B0D"
                    controlMb={0}
                  />
                  <Button
                    onClick={toggleFilters}
                    rightIcon={
                      showFilters ? (
                        <MdKeyboardArrowUp size="25px" />
                      ) : (
                        <MdKeyboardArrowDown size="25px" />
                      )
                    }
                  >
                    {showFilters ? 'Esconder Filtros' : 'Mostrar Filtros'}
                  </Button>
                </div>

                <div className="justify-end ">
                  <DateToolbarFilter setFilters={setFilters} filters={filters} showMonthYearOnly />
                </div>
              </div>

              <div
                className={`transition-all duration-500 ${showFilters ? 'max-h-screen opacity-100' : 'max-h-0 overflow-hidden opacity-0'}`}
              >
                <div className="mt-4 flex justify-between gap-2 border-slate-400 shadow-sm">
                  <form className="grid grid-cols-2 gap-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6">
                    <SelectInputForm<ICompany>
                      name="companies"
                      placeholder="Selecionar Empresa"
                      closeMenuOnSelect={false}
                      options={companies}
                      getOptionLabel={item => (item as ICompany).nome}
                      getOptionValue={item => String((item as ICompany).external_id)}
                      queryKeyName="CompaniesSelect"
                      getOptions={getCompanies}
                      isLoading={isFetchingCompanySelect}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="cidade"
                      placeholder="Filtrar por cidade"
                      options={getFormattedLabelString(CompaniesFilters?.cidade)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="uf"
                      placeholder="Filtrar por Estado"
                      options={getFormattedLabelString(CompaniesFilters?.uf)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="grupo_de_empresas"
                      placeholder="Filtrar por grupo de empresa"
                      options={getFormattedLabelString(CompaniesFilters?.grupo_de_empresas)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="tags"
                      placeholder="Filtrar por tags"
                      options={getFormattedLabelString(CompaniesFilters?.tags)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="comercial"
                      placeholder="Filtrar por comercial"
                      options={getFormattedLabelString(CompaniesFilters?.comercial)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="contabil"
                      placeholder="Filtrar pelo contábil"
                      options={getFormattedLabelString(CompaniesFilters?.contabil)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="financeiro"
                      placeholder="Filtrar pelo financeiro"
                      options={getFormattedLabelString(CompaniesFilters?.financeiro)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="contas_a_pagar"
                      placeholder="Filtrar por contas à pagar"
                      options={getFormattedLabelString(CompaniesFilters?.contas_a_pagar)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="encerramento_anual"
                      placeholder="Filtrar por encerramento anual"
                      options={getFormattedLabelString(CompaniesFilters?.encerramento_anual)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="fiscal"
                      placeholder="Filtrar por fiscal"
                      options={getFormattedLabelString(CompaniesFilters?.fiscal)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="imposto_de_renda"
                      placeholder="filtrar por imposto de renda"
                      options={getFormattedLabelString(CompaniesFilters?.imposto_de_renda)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="integracao_contabil"
                      placeholder="Filtrar por integração contabil"
                      options={getFormattedLabelString(CompaniesFilters?.integracao_contabil)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="integracao_fiscal"
                      placeholder="Filtrar por integração fiscal"
                      options={getFormattedLabelString(CompaniesFilters?.integracao_fiscal)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="pessoal"
                      placeholder="Filtrar por pessoal"
                      options={getFormattedLabelString(CompaniesFilters?.pessoal)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="relacionamento_com_cliente"
                      placeholder="Filtrar por relacionamento com cliente"
                      options={getFormattedLabelString(
                        CompaniesFilters?.relacionamento_com_cliente,
                      )}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="societario"
                      placeholder="Filtrar por societário"
                      options={getFormattedLabelString(CompaniesFilters?.societario)}
                      controlMb={0}
                    />
                  </form>
                </div>
              </div>
            </FormProvider>
          </div>
          <DataTable<ICompany>
            columns={columns}
            data={companiesList}
            pagination={pagination}
            emptyMsg="Nenhuma empresa encontrada."
            loadingMsg="Carregando Empresas..."
            isFetching={isFetchingCompanyList}
          />
        </div>
      </FormBox>
    </div>
  );
}
