import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { useQuery } from '@tanstack/react-query';
import { createSearchParams, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import { Flex, HStack, Text } from '@chakra-ui/react';
import { ReturnButton } from '../../../../../components/buttons/ReturnButton';
import { IData, IPagination } from '../../../../../types/shared';
import { ISaida, useCompanies } from '../../../../../hooks/useCompanies';
import { DataTable } from '../../../../../components/datatable';
import { FormBox } from '../../../../../components/formBox';
import SelectInputForm from '../../../../../components/inputs/forms/SelectInputForm';
import { DateToolbarFilter } from '../../../../../components/DateToolbarFilter';
import { formatCNPJ } from '../../../../../utils/FormatFunctions';

export function ModulesHomeIntegrationApiXSiegNotasSiegPage() {
  const { getCompaniesSaidasXml } = useCompanies();
  const { itemId } = useParams();
  const location = useLocation();
  const [company] = useState(location.state?.company);
  const columns: ColumnDef<ISaida>[] = [
    {
      accessorKey: 'nume_sai',
      header: 'Número de saída',
    },
    {
      accessorKey: 'seri_sai',
      header: 'Série de saida',
    },
    {
      accessorKey: 'sigl_est',
      header: 'Estado',
    },
    {
      accessorKey: 'verificado_dominio',
      header: 'Está na dominio?',
      cell: ({ row }) => {
        const value = row.original.verificado_dominio;
        return value ? 'Está no dominio' : 'Não está no dominio';
      },
    },
  ];

  const [filters, setFilters] = useSearchParams(
    createSearchParams({
      page: '1',
      size: '10',
      search: '',
      empresa_id: itemId || '',
    }),
  );

  const filtersFormMethods = useForm<{
    empresa_id: string;
    page: string;
    especie?: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      especie: filters.get('especie') ?? undefined,
    },
  });

  const debounced = useDebounceCallback(values => {
    const currentFilters = Object.fromEntries([...Array.from(filters)]);

    if (values?.especie?.value) {
      setFilters(
        createSearchParams({
          ...currentFilters,
          especie: values?.especie?.value || '',
          empresa_id: itemId || '',
          page: '1',
        }),
      );
    } else {
      setFilters(
        createSearchParams({
          ...currentFilters,
          empresa_id: itemId || '',
          page: '1',
        }),
      );
    }
  }, 200);

  useEffect(() => {
    const subscription = filtersFormMethods.watch(debounced);
    return () => subscription.unsubscribe();
  }, [filtersFormMethods.watch]);

  const { data: dataCompaniesList, isFetching: isFetchingCompanyList } = useQuery<IData<ISaida[]>>({
    queryKey: ['CompaniesList', Object.fromEntries([...Array.from(filters)])],
    queryFn: getCompaniesSaidasXml,
  });

  const { items: companiesList, ...pagination } = dataCompaniesList?.data ?? {
    items: [],
    ...({} as IPagination),
  };

  return (
    <div className="flex w-full flex-col gap-10">
      <ReturnButton
        label="Voltar para página anterior"
        previousPage="modulesHome/integration/apixsieg"
      />
      <div className="grid grid-cols-1 gap-3 md:grid-cols-3">
        <Flex className="flex cursor-pointer items-center gap-3 rounded-3xl bg-body p-5">
          <div className="flex w-full flex-col ">
            <Text fontSize="10px" color="#718EBF">
              ID
            </Text>
            <Text fontSize="14px" fontWeight="bold">
              {company?.id}
            </Text>
          </div>
        </Flex>
        <Flex className="flex cursor-pointer items-center gap-3 rounded-3xl bg-body p-5">
          <div className="flex w-full flex-col ">
            <Text fontSize="10px" color="#718EBF">
              CNPJ
            </Text>
            <Text fontSize="14px" fontWeight="bold">
              {formatCNPJ(company?.cnpj)}
            </Text>
          </div>
        </Flex>
        <Flex className="flex cursor-pointer items-center gap-3 rounded-3xl bg-body p-5">
          <div className="flex w-full flex-col ">
            <Text fontSize="10px" color="#718EBF">
              Razão social
            </Text>
            <Text fontSize="14px" fontWeight="bold">
              {company?.razao_social}
            </Text>
          </div>
        </Flex>
      </div>
      <HStack justify="flex-end" mt={8}>
        <DateToolbarFilter filters={filters} setFilters={setFilters} />
      </HStack>

      <FormBox>
        <div className="flex flex-col gap-4  p-4">
          <div className="flex flex-col justify-between gap-2">
            <FormProvider {...filtersFormMethods}>
              <SelectInputForm<string>
                name="especie"
                label="Selecione a espécie"
                options={[
                  { label: 'nfce', value: 'nfce' },
                  { label: 'nfse', value: 'nfse' },
                  { label: 'nfe', value: 'nfe' },
                  { label: 'cte', value: 'cte' },
                ]}
              />
            </FormProvider>
          </div>
          <DataTable<ISaida>
            columns={columns}
            data={companiesList}
            pagination={pagination}
            emptyMsg="Nenhuma Nota encontrada."
            loadingMsg="Carregando Notas..."
            isFetching={isFetchingCompanyList}
            getRowClassName={row =>
              row.verificado_sieg
                ? 'border border-2 border-green-500'
                : 'border border-2 border-red-500'
            }
          />
        </div>
      </FormBox>
    </div>
  );
}
