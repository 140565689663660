/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Button, Spinner, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { useQuery } from '@tanstack/react-query';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { createSearchParams, Link, useLocation, useSearchParams } from 'react-router-dom';
import { User } from 'phosphor-react';
import moment from 'moment';
import { ReturnButton } from '../../../../components/buttons/ReturnButton';
import { InputForm } from '../../../../components/inputs/forms/InputForm';
import { FormBox } from '../../../../components/formBox';
import SelectInputForm from '../../../../components/inputs/forms/SelectInputForm';
import { IData, IPagination, ISelect } from '../../../../types/shared';
import { ICompany, IPartner, useCompanies } from '../../../../hooks/useCompanies';
import { IClient } from '../../../../hooks/useClients';
import { getFormattedLabelString, toCurrency } from '../../../../utils/FormatFunctions';
import { Paginator } from '../../../../components/paginator';
import { YearSelector } from '../../../../components/DateToolbarFilterOnlyYear';

export function ModulesHomeSupervisorPartinerPage() {
  const { getPartnerCompany, getCompanies, getCompaniesFilters } = useCompanies();
  const [showFilters, setShowFilters] = useState(false);
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const searchParamStart = queryParams.get('date_start');
  const searchParamEnd = queryParams.get('date_end');

  const toggleFilters = () => setShowFilters(prev => !prev);
  const [filters, setFilters] = useSearchParams(
    createSearchParams({
      page: '1',
      size: '50',
      search: '',
      companyId: '',
      screen_data: 'faturamento',
    }),
  );

  const dateStart = filters.get('date_start');
  const dateEnd = filters.get('date_end');

  const filtersFormMethods = useForm<{
    search: string;
    companyId: any;
    cliente: IClient;
    cidade: ISelect<string>;
    uf: ISelect<string>;
    grupo_de_empresas: ISelect<string>;
    tags: ISelect<string>;
    comercial: ISelect<string>;
    contabil: ISelect<string>;
    financeiro: ISelect<string>;
    contas_a_pagar: ISelect<string>;
    encerramento_anual: ISelect<string>;
    fiscal: ISelect<string>;
    imposto_de_renda: ISelect<string>;
    integracao_contabil: ISelect<string>;
    integracao_fiscal: ISelect<string>;
    pessoal: ISelect<string>;
    relacionamento_com_cliente: ISelect<string>;
    societario: ISelect<string>;
    situacao: 'ok' | 'aviso' | 'erro' | 'outro' | null;
  }>({
    mode: 'onChange',
    defaultValues: {
      search: filters.get('search') ?? '',
    },
  });

  const debounced = useDebounceCallback(values => {
    const fields = {
      search: values.search,
      date_start: searchParamStart ?? filters.get('date_start') ?? '',
      date_end: searchParamEnd ?? filters.get('date_end') ?? '',
      id: values.companies?.external_id,
      cidade: values.cidade?.value,
      uf: values.uf?.value,
      grupo_de_empresas: values.grupo_de_empresas?.value,
      tags: values.tags?.value,
      comercial: values.comercial?.value,
      contabil: values.contabil?.value,
      financeiro: values.financeiro?.value,
      contas_a_pagar: values.contas_a_pagar?.value,
      encerramento_anual: values.encerramento_anual?.value,
      fiscal: values.fiscal?.value,
      imposto_de_renda: values.imposto_de_renda?.value,
      integracao_contabil: values.integracao_contabil?.value,
      integracao_fiscal: values.integracao_fiscal?.value,
      pessoal: values.pessoal?.value,
      relacionamento_com_cliente: values.relacionamento_com_cliente?.value,
      societario: values.societario?.value,
    };

    Object.entries(fields).forEach(([key, value]) => {
      if (value) filters.set(key, value);
      else filters.delete(key);
    });

    setFilters(filters);
  }, 500);

  useEffect(() => {
    const subscription = filtersFormMethods.watch(values => {
      debounced(values);
    });

    return () => subscription.unsubscribe();
  }, [filtersFormMethods.watch, searchParamStart, searchParamEnd]);

  const queryKeyCompaniesFilters = ['CompaniesFilters'];
  const { data: CompaniesFiltersData } = useQuery({
    queryKey: queryKeyCompaniesFilters,
    queryFn: getCompaniesFilters,
  });

  const CompaniesFilters = CompaniesFiltersData;

  const { data: dataCompaniesSelect, isFetching: isFetchingCompanySelect } = useQuery<
    IData<ICompany[]>
  >({
    queryKey: ['CompaniesSelect', { page: 1, size: 30, search: '' }],
    queryFn: getCompanies,
  });

  const { data: dataPartnerList, isFetching: isFetchingPartnerList } = useQuery<IData<IPartner[]>>({
    queryKey: ['partnerList', Object.fromEntries([...Array.from(filters)])],
    queryFn: getPartnerCompany,
  });

  const { items: companies } = dataCompaniesSelect?.data ?? {
    items: [],
    ...({} as IPagination),
  };

  const { items: partnerList, ...pagination } = dataPartnerList?.data ?? {
    items: [],
    ...({} as IPagination),
  };

  return (
    <div className="flex w-full flex-col gap-2">
      <div className="flex flex-col items-center justify-between gap-3 py-5 lg:flex-row">
        <div className="flex content-start md:w-1/3 lg:content-center ">
          <ReturnButton previousPage="modulesHome/supervisor" />

          <div className="h-full">
            <div className="flex items-center justify-center rounded-full bg-[#CFD8DC] p-3">
              <User color="#37474F" className="size-8" />
            </div>
          </div>
          <div className="flex w-full flex-col justify-center gap-1 pl-2">
            <Text fontWeight="bold" fontSize="16px">
              REGIME TRIBUTÁRIO SÓCIOS
            </Text>
          </div>
        </div>
      </div>

      <FormBox>
        <div className="flex flex-col gap-4  p-4">
          <div className="flex flex-col justify-between gap-2">
            <FormProvider {...filtersFormMethods}>
              <div className="flex flex-col justify-between gap-4 lg:flex-row">
                <div className="flex content-center items-center justify-center gap-3">
                  {/* colocar pt-10 caso precise ter filtro de data na div acima do comentário */}
                  <InputForm
                    name="search"
                    placeholder="Buscar por nome, cnpj, razão social"
                    boxShadow="0px 2px 4px #1E293B0D"
                    controlMb={0}
                  />
                  <Button
                    onClick={toggleFilters}
                    rightIcon={
                      showFilters ? (
                        <MdKeyboardArrowUp size="25px" />
                      ) : (
                        <MdKeyboardArrowDown size="25px" />
                      )
                    }
                  >
                    {showFilters ? 'Esconder Filtros' : 'Mostrar Filtros'}
                  </Button>
                </div>

                <div className="mb-7 justify-end">
                  <YearSelector setFilters={setFilters} filters={filters} />
                </div>
              </div>

              <div
                className={`transition-all duration-500 ${showFilters ? 'max-h-screen opacity-100' : 'max-h-0 overflow-hidden opacity-0'}`}
              >
                <div className="mt-4 flex justify-between gap-2 border-slate-400">
                  <form className="grid grid-cols-2 gap-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-6">
                    <SelectInputForm<ICompany>
                      name="companies"
                      placeholder="Selecionar Empresa"
                      closeMenuOnSelect={false}
                      options={companies}
                      getOptionLabel={item => (item as ICompany).nome}
                      getOptionValue={item => String((item as ICompany).external_id)}
                      queryKeyName="CompaniesSelect"
                      getOptions={getCompanies}
                      isLoading={isFetchingCompanySelect}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="cidade"
                      placeholder="Filtrar por cidade"
                      options={getFormattedLabelString(CompaniesFilters?.cidade)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="uf"
                      placeholder="Filtrar por Estado"
                      options={getFormattedLabelString(CompaniesFilters?.uf)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="grupo_de_empresas"
                      placeholder="Filtrar por grupo de empresa"
                      options={getFormattedLabelString(CompaniesFilters?.grupo_de_empresas)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="tags"
                      placeholder="Filtrar por tags"
                      options={getFormattedLabelString(CompaniesFilters?.tags)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="comercial"
                      placeholder="Filtrar por comercial"
                      options={getFormattedLabelString(CompaniesFilters?.comercial)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="contabil"
                      placeholder="Filtrar pelo contábil"
                      options={getFormattedLabelString(CompaniesFilters?.contabil)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="financeiro"
                      placeholder="Filtrar pelo financeiro"
                      options={getFormattedLabelString(CompaniesFilters?.financeiro)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="contas_a_pagar"
                      placeholder="Filtrar por contas à pagar"
                      options={getFormattedLabelString(CompaniesFilters?.contas_a_pagar)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="encerramento_anual"
                      placeholder="Filtrar por encerramento anual"
                      options={getFormattedLabelString(CompaniesFilters?.encerramento_anual)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="fiscal"
                      placeholder="Filtrar por fiscal"
                      options={getFormattedLabelString(CompaniesFilters?.fiscal)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="imposto_de_renda"
                      placeholder="filtrar por imposto de renda"
                      options={getFormattedLabelString(CompaniesFilters?.imposto_de_renda)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="integracao_contabil"
                      placeholder="Filtrar por integração contabil"
                      options={getFormattedLabelString(CompaniesFilters?.integracao_contabil)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="integracao_fiscal"
                      placeholder="Filtrar por integração fiscal"
                      options={getFormattedLabelString(CompaniesFilters?.integracao_fiscal)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="pessoal"
                      placeholder="Filtrar por pessoal"
                      options={getFormattedLabelString(CompaniesFilters?.pessoal)}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="relacionamento_com_cliente"
                      placeholder="Filtrar por relacionamento com cliente"
                      options={getFormattedLabelString(
                        CompaniesFilters?.relacionamento_com_cliente,
                      )}
                      controlMb={0}
                    />
                    <SelectInputForm<ISelect<string>>
                      name="societario"
                      placeholder="Filtrar por societário"
                      options={getFormattedLabelString(CompaniesFilters?.societario)}
                      controlMb={0}
                    />
                  </form>
                </div>
              </div>
            </FormProvider>
          </div>
          {isFetchingPartnerList ? (
            <div className="flex flex-col items-center gap-4">
              <Spinner size="lg" color="primary" />
              <span className="text-xl font-semibold">Carregando...</span>
            </div>
          ) : partnerList?.length === 0 ? (
            <div className="flex flex-col items-center gap-4">Nenhum item encontrado</div>
          ) : filters.get('search') && partnerList?.length === 0 && !isFetchingPartnerList ? (
            <div className="flex flex-col items-center gap-4">Nenhum item encontrado</div>
          ) : (
            <div className="grid grid-flow-row grid-cols-1 items-center justify-between gap-2">
              {partnerList?.map(item => (
                <div className="flex gap-4 rounded-lg border p-4 shadow-lg">
                  <div className="grid w-full grid-cols-2 justify-between gap-9 lg:grid-cols-4">
                    <div className="flex flex-col items-start gap-2">
                      <span className="text-base font-bold">ID</span>
                      <div className="flex items-center gap-2">
                        <Text className="text-slate-500">{item?.socio_id}</Text>
                      </div>
                    </div>
                    <div className="flex flex-col items-start gap-2">
                      <span className="text-base font-bold">Nome do sócio</span>
                      <div className="flex items-center gap-2">
                        <Text className="text-slate-500">{item?.nome_socio}</Text>
                      </div>
                    </div>
                    <div className="flex flex-col items-start gap-2">
                      <span className="text-base font-bold">Inscrição</span>
                      <div className="flex items-center gap-2">
                        <Text className="text-slate-500">{item?.inscricao}</Text>
                      </div>
                    </div>

                    <div className="flex flex-col items-start gap-2">
                      <span className="text-base font-bold">Faturamento acumulado</span>
                      <div className="flex items-center gap-2">
                        <Text className="text-slate-500">
                          {toCurrency(item?.faturamento_acumulado)}
                        </Text>
                      </div>
                    </div>
                    {/* <div className="flex flex-col items-start gap-2">
                      <span className="text-base font-bold">Análise de regime</span>
                      <div className="flex items-center gap-2">
                        <Text className="text-slate-500">{item?.faixa_atual}</Text>
                      </div>
                    </div>
                    <div className="flex flex-col items-start gap-2">
                      <span className="text-base font-bold">Porcentagem do Limite</span>
                      <div className="flex items-center gap-2">
                        <Text className="text-slate-500">{item?.limite_atingido}%</Text>
                      </div>
                    </div> */}
                  </div>

                  <div className="flex items-center justify-center lg:pt-1">
                    <Link
                      to={{
                        pathname: `/supervisor/partner/${item.socio_id}`,
                        search: createSearchParams({
                          date_start: dateStart ?? moment().startOf('year').format('YYYY-MM-DD'),
                          date_end: dateEnd ?? moment().endOf('year').format('YYYY-MM-DD'),
                        }).toString(),
                      }}
                      state={{ company: item }}
                    >
                      <Button
                        variant="outline"
                        h="fit-content"
                        w="100%"
                        cursor="pointer"
                        alignItems="center"
                        justifyContent="center"
                        borderRadius="full"
                        border="2px solid"
                        borderColor="#718EBF"
                        bg="white"
                        p={2}
                      >
                        <Text className="text-sm" color="#718EBF">
                          Ver empresas
                        </Text>
                      </Button>
                    </Link>
                  </div>
                </div>
              ))}
              <Paginator pagination={pagination} />
            </div>
          )}
        </div>
      </FormBox>
    </div>
  );
}
