import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { useQuery } from '@tanstack/react-query';
import { createSearchParams, useLocation, useSearchParams } from 'react-router-dom';
import { ColumnDef } from '@tanstack/react-table';
import { Flex, HStack, Text } from '@chakra-ui/react';
import { ReturnButton } from '../../../../../components/buttons/ReturnButton';
import { IData, IPagination } from '../../../../../types/shared';
import { INotasManualPosting, useCompanies } from '../../../../../hooks/useCompanies';
import { DataTable } from '../../../../../components/datatable';
import { FormBox } from '../../../../../components/formBox';
import { DateToolbarFilter } from '../../../../../components/DateToolbarFilter';
import { formatCNPJ } from '../../../../../utils/FormatFunctions';
import { InputForm } from '../../../../../components/inputs/forms/InputForm';

export function ViewNotasManualPosting() {
  const { getNotasManualPosting } = useCompanies();
  const location = useLocation();
  const [company] = useState(location.state?.company);

  const columns: ColumnDef<INotasManualPosting>[] = [
    {
      accessorKey: 'numero',
      header: 'Número da nota',
    },
    {
      accessorKey: 'especie',
      header: 'Espécie da nota',
    },
    {
      accessorKey: 'empresa.nome',
      header: 'Nome da empresa',
    },
    {
      accessorKey: 'empresa.external_id',
      header: 'ID da empresa',
    },
    {
      accessorKey: 'empresa.cnpj',
      header: 'CNPJ da empresa',
      cell: ({ row }) => {
        const value = row.original;
        return formatCNPJ(value?.empresa?.cnpj);
      },
    },
  ];

  const [filters, setFilters] = useSearchParams(
    createSearchParams({
      page: '1',
      size: '10',
      search: '',
      codi_usu: company?.name,
    }),
  );

  const filtersFormMethods = useForm<{
    page: string;
    search?: string;
  }>({
    mode: 'onChange',
    defaultValues: {
      search: filters.get('search') ?? undefined,
    },
  });

  const debounced = useDebounceCallback(values => {
    const currentFilters = Object.fromEntries([...Array.from(filters)]);

    if (values?.search) {
      setFilters(
        createSearchParams({
          ...currentFilters,
          search: values?.search || '',
          page: '1',
        }),
      );
    } else {
      setFilters(
        createSearchParams({
          ...currentFilters,
          page: '1',
        }),
      );
    }
  }, 200);

  useEffect(() => {
    const subscription = filtersFormMethods.watch(debounced);
    return () => subscription.unsubscribe();
  }, [filtersFormMethods.watch]);

  const { data: dataCompaniesList, isFetching: isFetchingCompanyList } = useQuery<
    IData<INotasManualPosting[]>
  >({
    queryKey: ['CompaniesList', Object.fromEntries([...Array.from(filters)])],
    queryFn: getNotasManualPosting,
  });

  const { items: companiesList, ...pagination } = dataCompaniesList?.data ?? {
    items: [],
    ...({} as IPagination),
  };

  return (
    <div className="flex w-full flex-col gap-10">
      <ReturnButton
        label="Voltar para página anterior"
        previousPage="modulesHome/integration/manualPostings"
      />
      <div className="grid grid-cols-1 justify-end gap-3 md:grid-cols-1">
        <Flex className="flex cursor-pointer items-center gap-3 rounded-3xl bg-body p-5">
          <div className="flex w-full flex-col ">
            <Text fontSize="10px" color="#718EBF">
              Nome
            </Text>
            <Text fontSize="14px" fontWeight="bold">
              {company?.name}
            </Text>
          </div>
        </Flex>
      </div>
      <HStack justify="flex-end" mt={8}>
        <DateToolbarFilter filters={filters} setFilters={setFilters} showMonthYearOnly />
      </HStack>

      <FormBox>
        <div className="flex flex-col gap-4  p-4">
          <div className="flex flex-col justify-between gap-2">
            <FormProvider {...filtersFormMethods}>
              <div className="flex content-center items-center justify-center gap-3 pt-5">
                <InputForm
                  name="search"
                  placeholder="Buscar por nome, cnpj, razão social"
                  boxShadow="0px 2px 4px #1E293B0D"
                  controlMb={0}
                />
              </div>
            </FormProvider>
          </div>
          <DataTable<INotasManualPosting>
            columns={columns}
            data={companiesList}
            pagination={pagination}
            emptyMsg="Nenhuma Nota encontrada."
            loadingMsg="Carregando Notas..."
            isFetching={isFetchingCompanyList}
          />
        </div>
      </FormBox>
    </div>
  );
}
