/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { Button, Flex, Spinner, Text } from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useDebounceCallback } from 'usehooks-ts';
import { useQuery } from '@tanstack/react-query';
import { createSearchParams, Link, useSearchParams } from 'react-router-dom';
import moment from 'moment';
import { NotePencil } from 'phosphor-react';
import { ReturnButton } from '../../../../components/buttons/ReturnButton';
import { InputForm } from '../../../../components/inputs/forms/InputForm';
import { FormBox } from '../../../../components/formBox';
import { IData, IPagination, ISelect } from '../../../../types/shared';
import { ISaidaImport, useCompanies } from '../../../../hooks/useCompanies';
import { Paginator } from '../../../../components/paginatorNoSearchParams';
import { DateToolbarFilter } from '../../../../components/DateToolbarFilterNoSearchParams';
import { PageBox } from '../../../../components/pageBox';
import SelectInputForm from '../../../../components/inputs/forms/SelectInputForm';

export function ModulesHomeIntegrationManualPostingsPage() {
  const { getCompaniesSaidasImports, getSaidasImportsTotais } = useCompanies();
  const [searchParams, setSearchParams] = useSearchParams();

  const [filters, setFilters] = useState({
    search: searchParams.get('search') || '',
    date_start: searchParams.get('date_start') || moment().startOf('month').format('YYYY-MM-DD'),
    date_end: searchParams.get('date_end') || moment().endOf('month').format('YYYY-MM-DD'),
    page: '1',
    size: '50',
  });

  const dateStart = filters.date_start;
  const dateEnd = filters.date_end;

  const filtersFormMethods = useForm<{ search: string }>({
    mode: 'onChange',
    defaultValues: {
      search: filters.search,
    },
  });

  const debounced = useDebounceCallback(values => {
    const ordem = values?.order_by?.value;
    if (values?.search) {
      setFilters(prev => ({
        ...prev,
        search: values.search,
        page: '1',
      }));
    }
    if (ordem === 'Maior NFE') {
      setFilters(prev => ({
        ...prev,
        order: 'desc',
        order_by: 'nfe',
        page: '1',
      }));
    }
    if (ordem === 'Menor NFE') {
      setFilters(prev => ({
        ...prev,
        order: 'asc',
        order_by: 'nfe',
        page: '1',
      }));
    }
    if (ordem === 'Maior NFCE') {
      setFilters(prev => ({
        ...prev,
        order: 'desc',
        order_by: 'nfce',
        page: '1',
      }));
    }
    if (ordem === 'Menor NFCE') {
      setFilters(prev => ({
        ...prev,
        order: 'asc',
        order_by: 'nfce',
        page: '1',
      }));
    }
    if (ordem === 'Maior NFSE') {
      setFilters(prev => ({
        ...prev,
        order: 'desc',
        order_by: 'nfse',
        page: '1',
      }));
    }
    if (ordem === 'Menor NFSE') {
      setFilters(prev => ({
        ...prev,
        order: 'asc',
        order_by: 'nfse',
        page: '1',
      }));
    }
    if (ordem === undefined) {
      setFilters({
        search: searchParams.get('search') || '',
        date_start:
          searchParams.get('date_start') || moment().startOf('month').format('YYYY-MM-DD'),
        date_end: searchParams.get('date_end') || moment().endOf('month').format('YYYY-MM-DD'),
        page: '1',
        size: '10',
      });
    }
  }, 500);

  useEffect(() => {
    const subscription = filtersFormMethods.watch(debounced);
    return () => subscription.unsubscribe();
  }, [filtersFormMethods.watch]);

  useEffect(() => {
    setSearchParams(new URLSearchParams(filters));
  }, [filters]);

  const handleDateChange = (item: any) => {
    setFilters(prev => ({
      ...prev,
      date_start: item?.date_start,
      date_end: item?.date_end,
    }));
  };

  const queryKeyCompaniesMostFailures = ['queryKeyCompaniesMostFailures', filters];

  const { data: dataCompaniesList, isFetching: isFetchingCompanyList } = useQuery<
    IData<ISaidaImport[]>
  >({
    queryKey: queryKeyCompaniesMostFailures,
    queryFn: getCompaniesSaidasImports,
  });

  const { items: companiesList, ...pagination } = dataCompaniesList?.data ?? {
    items: [],
    ...({} as IPagination),
  };

  const { data: totais, isFetching: isFetchingTotais } = useQuery<any>({
    queryKey: ['getTotais', { date_start: filters.date_start, date_end: filters.date_end }],
    queryFn: getSaidasImportsTotais,
  });

  return (
    <PageBox>
      <div className="flex flex-col items-center justify-between gap-3 py-5 lg:flex-row">
        <div className="flex content-start md:w-1/3 lg:content-center ">
          <ReturnButton previousPage="modulesHome/integration" />

          <div className="h-full">
            <div className="flex items-center justify-center rounded-full bg-[#D9F1F1] p-3">
              <NotePencil color="#37474F" className="size-8" />
            </div>
          </div>
          <div className="flex w-full flex-col justify-center gap-1 pl-2">
            <Text fontWeight="bold" fontSize="16px">
              Lançamentos Manuais
            </Text>
          </div>
        </div>

        <div className="mb-5 grid grid-cols-1 gap-3 md:grid-cols-3">
          {isFetchingTotais ? (
            <div className="flex flex-col items-center gap-4">
              <Spinner size="lg" color="primary" />
              <span className="text-xl font-semibold">Carregando...</span>
            </div>
          ) : (
            <>
              <Flex className="flex cursor-pointer items-center gap-3 rounded-3xl bg-body p-5">
                <div className="flex w-full flex-col ">
                  <Text fontSize="14px" color="#718EBF">
                    Total NFE
                  </Text>
                  <Text fontSize="18px" fontWeight="bold">
                    {totais?.quantity_nfe ?? 0}
                  </Text>
                </div>
              </Flex>

              <Flex className="flex cursor-pointer items-center gap-3 rounded-3xl bg-body p-5">
                <div className="flex w-full flex-col ">
                  <Text fontSize="14px" color="#718EBF">
                    Total NFCE
                  </Text>
                  <Text fontSize="18px" fontWeight="bold">
                    {totais?.quantity_nfce ?? 0}
                  </Text>
                </div>
              </Flex>

              <Flex className="flex cursor-pointer items-center gap-3 rounded-3xl bg-body p-5">
                <div className="flex w-full flex-col ">
                  <Text fontSize="14px" color="#718EBF">
                    Total NFSE
                  </Text>
                  <Text fontSize="18px" fontWeight="bold">
                    {totais?.quantity_nfse ?? 0}
                  </Text>
                </div>
              </Flex>
            </>
          )}
        </div>
      </div>

      <FormBox>
        <div className="flex flex-col gap-4  p-4">
          <div className="flex flex-col justify-between gap-2">
            <FormProvider {...filtersFormMethods}>
              <div className="flex flex-col justify-between gap-4 lg:flex-row">
                <div className="flex content-center items-center justify-center gap-3 pt-5">
                  <InputForm
                    name="search"
                    placeholder="Buscar por nome, cnpj, razão social"
                    boxShadow="0px 2px 4px #1E293B0D"
                    controlMb={0}
                  />
                </div>
                <div className="pt-3">
                  <SelectInputForm<ISelect<string>>
                    name="order_by"
                    label="Ordenar por"
                    options={[
                      { label: 'Maior NFE', value: 'Maior NFE' },
                      { label: 'Menor NFE', value: 'Menor NFE' },
                      { label: 'Maior NFCE', value: 'Maior NFCE' },
                      { label: 'Menor NFCE', value: 'Menor NFCE' },
                      { label: 'Maior NFSE', value: 'Maior NFSE' },
                      { label: 'Menor NFSE', value: 'Menor NFSE' },
                    ]}
                    placeholder="Selecione a ordem"
                  />
                </div>
                <div className="justify-end ">
                  <DateToolbarFilter
                    setFilters={handleDateChange}
                    filters={filters}
                    showMonthYearOnly
                  />
                </div>
              </div>
            </FormProvider>
          </div>
          {isFetchingCompanyList ? (
            <div className="flex flex-col items-center gap-4">
              <Spinner size="lg" color="primary" />
              <span className="text-xl font-semibold">Carregando...</span>
            </div>
          ) : companiesList?.length === 0 ? (
            <div className="flex flex-col items-center gap-4">Nenhum item encontrado</div>
          ) : filters?.search && companiesList?.length === 0 && !isFetchingCompanyList ? (
            <div className="flex flex-col items-center gap-4">Nenhum item encontrado</div>
          ) : (
            <div className="grid grid-flow-row grid-cols-1 items-center justify-between gap-2">
              {companiesList?.map(item => (
                <div className="flex gap-4 rounded-lg border border-border bg-body p-4 shadow-lg lg:flex-col">
                  <div className="grid grid-cols-1 gap-9 lg:grid-cols-2">
                    <div className="flex flex-col gap-2 ">
                      <span className="text-base font-bold">{item?.name}</span>
                      <span className="text-sm  text-[#718EBF]">{item?.origin}</span>
                    </div>
                    <div className="grid grid-cols-2 gap-2 lg:grid-cols-5">
                      <div className="flex flex-col gap-2 ">
                        <span className="text-base font-bold">NFE</span>
                        <div>
                          <div className="flex items-center gap-2">
                            <Text className="text-slate-500">{item.quantity_nfe ?? 0}</Text>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2 ">
                        <span className="text-base font-bold">NFCE</span>
                        <div>
                          <div className="flex items-center gap-2">
                            <Text className="text-slate-500">{item.quantity_nfce ?? 0}</Text>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <span className="text-base font-bold">NFSE</span>
                        <div>
                          <div className="flex items-center gap-2">
                            <Text className="text-slate-500">{item.quantity_nfse ?? 0}</Text>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-col gap-2">
                        <span className="text-base font-bold">Total</span>
                        <div>
                          <div className="flex items-center gap-2">
                            <Text className="text-slate-500">{item.quantity_total ?? 0}</Text>
                          </div>
                        </div>
                      </div>

                      <div className="col-span-1 pt-2">
                        <Link
                          to={{
                            pathname: `${item.id}`,
                            search: createSearchParams({
                              date_start:
                                dateStart || moment().subtract(6, 'days').format('YYYY-MM-DD'),
                              date_end: dateEnd || moment().format('YYYY-MM-DD'),
                            }).toString(),
                          }}
                          state={{ company: item }}
                        >
                          <Button
                            variant="outline"
                            h="fit-content"
                            w="100%"
                            cursor="pointer"
                            alignItems="center"
                            justifyContent="center"
                            borderRadius="full"
                            border="2px solid"
                            borderColor="#718EBF"
                            bg="white"
                            p={2}
                          >
                            <Text className="text-sm" color="#718EBF">
                              Ver notas
                            </Text>
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <Paginator pagination={pagination} setFilters={setFilters} />
            </div>
          )}
        </div>
      </FormBox>
    </PageBox>
  );
}
