import { QueryFunctionContext } from '@tanstack/react-query';
import _ from 'lodash';
import { api } from '../services/api';
import { IData, IDataNoPagination } from '../types/shared';

export interface ICompany {
  id?: 0;
  dt_criacao: any;
  dt_atualizacao: any;
  nome: string;
  cnpj: string;
  razao_social: string;
  external_id: string;
  external_id_column: string;
  quebras: number;
  sem_quebras: number;
  nf_quebradas: any;
  quantidade_notas: number;
  variacao_faturamento: number;
  faturamento_anterior: number;
  faturamento_atual: number;
  impostos_guia: IImposto;
  impostos_calculo: IImposto;
  cidade: any;
  uf: any;
  dt_cadastro: any;
  dt_cli_desde: any;
  dt_cli_ate: any;
  ativa: any;
  grupo_de_empresas: any;
  tags: any;
  comercial: any;
  contabil: any;
  financeiro: any;
  contas_a_pagar: any;
  encerramento_anual: any;
  fiscal: any;
  imposto_de_renda: any;
  integracao_contabil: any;
  integracao_fiscal: any;
  pessoal: any;
  relacionamento_com_cliente: any;
  societario: any;
  quantidade_cte: number;
  quantidade_nfe: number;
  quantidade_nfce: number;
  quantidade_nfse: number;
}

export interface ICompaniesSituations {
  ok: number;
  aviso: number;
  erro: number;
}

export interface IImposto {
  cofins: number;
  csll: number;
  icms: number;
  irpj: number;
  pis: number;
  vl_simples_nacional?: number;
  anexo_simles_nacional?: number;
  secao_simples_nacional?: number;
  tabela_simples_nacional?: number;
  simples_nacional_pdf?: string;
}

export interface ICompanyQuebrasTotais {
  alertas?: number;
  quebras?: number;
  sem_quebras?: number;
}

export interface ISaida {
  cnae_descricao: any;
  verificado_sieg: boolean;
  verificado_dominio: boolean;
  sigl_est: string;
  codi_emp: string;
  nume_sai: string;
  seri_sai: string;
  codi_esp: string;
  codi_acu: string;
  dsai_sai: any;
  data_emissao: any;
  dser_ser: any;
  ddoc_ser: any;
  novo_ecf: string;
  valor_contabil: number;
  chave_nfe: string;
  external_id: string;
  external_id_column: string;
  intermunicipal: number;
  tributo: ITributo;
  empresa_id: number;
}

export interface ISaidaImport {
  id?: number;
  name: string;
  origin: string;
  quantity_nfe: number;
  quantity_nfce: number;
  quantity_nfse: number;
  quantity_total?: number;
}

export interface ITributo {
  iss_retido: number;
  valor_iss: number;
  inss: number;
  csll: number;
  pis: number;
  cofins: number;
  valor_nfe: number;
}

interface ICompanyFilter {
  companyId: number;
}

export interface ITaxRegime {
  id: number;
  external_id: string;
  razao_social: string;
  cnpj: string;
  regime_tributario: string;
  faturamento_acumulado: number;
  faixa_atual: string;
  limite_restante: number;
  limite_atingido: number;
}

export interface IPartner {
  socio_id: string;
  nome_socio: string;
  inscricao: string;
  faturamento_acumulado: number;
  faixa_atual: string;
  limite_restante: number;
  limite_atingido: number;
}

export interface INotasManualPosting {
  numero: string;
  especie: string;
  empresa: {
    external_id: string;
    nome: string;
    cnpj: string;
    razao_social: string;
  };
}

export const useCompanies = () => {
  const getCompanies = async (key: QueryFunctionContext): Promise<IData<ICompany[]>> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<IData<ICompany[]>>('/api/v1/empresas/', {
      params: filters,
    });
    return res.data;
  };

  const getSituationsTotals = async (
    key: QueryFunctionContext,
  ): Promise<IData<ICompaniesSituations>> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<IData<ICompaniesSituations>>('/api/v1/empresas/faturamento/total/', {
      params: filters,
    });
    return res.data;
  };

  const getCompany = async (key: QueryFunctionContext): Promise<IData<ICompany>> => {
    const { companyId } = key.queryKey[1] as ICompanyFilter;
    const res = await api.get<IData<ICompany>>(`/api/v1/empresas/${companyId}/`);
    return res.data;
  };

  const createCompany = async (data: ICompany): Promise<IData<ICompany>> => {
    const res = await api.post<IData<ICompany>>('/api/v1/empresas/', data);
    return res.data;
  };

  const updateCompany = async (data: ICompany): Promise<IData<ICompany>> => {
    const res = await api.put<IData<ICompany>>(`/api/v1/empresas/${data.id}/`, data);
    return res.data;
  };

  const deleteCompany = async (companyId: string) => {
    const res = await api.delete(`/api/v1/empresas/${companyId}/`);
    return res.data;
  };

  const getQuebras = async (key: QueryFunctionContext): Promise<IData<ICompany[]>> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<IData<ICompany[]>>('/api/v1/empresas/quebras/', {
      params: filters,
    });
    return res.data;
  };

  const getQuebrasWithId = async (key: QueryFunctionContext): Promise<IData<number[]>> => {
    const filters = key.queryKey?.[1] ?? ({} as unknown as any);
    const res = await api.get<IData<number[]>>(`/api/v1/empresas/${filters?.companyId}/quebras/`, {
      params: _.omit(filters, 'companyId'),
    });
    return res.data;
  };

  const getQuebrasTotais = async (key: QueryFunctionContext): Promise<any> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<any>('/api/v1/empresas/quebras/total/', {
      params: filters,
    });
    return res.data;
  };
  const getAPIXSiegTotais = async (key: QueryFunctionContext): Promise<any> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<any>('/api/v1/empresas/api_sieg/total/', {
      params: filters,
    });
    return res.data;
  };
  const getFaturamentoTotais = async (key: QueryFunctionContext): Promise<any> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<any>('/api/v1/empresas/faturamento/total/', {
      params: filters,
    });
    return res.data;
  };

  const uploadCompanyFile = async ({
    file,
  }: {
    file: HTMLInputElement;
  }): Promise<IDataNoPagination<ICompany>> => {
    const formData = new FormData();
    formData.append('file', file[0]);
    const res = await api.post('/api/v1/empresas/import/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Ensure correct Content-Type header for FormData
      },
    });
    return res.data;
  };

  const getCompaniesFilters = async () => {
    const res = await api.get(`/api/v1/empresas/filters/`);
    return res.data;
  };

  const getCompaniesSaidas = async (key: QueryFunctionContext): Promise<IData<ISaida[]>> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<IData<ISaida[]>>('/api/v1/saidas/', {
      params: filters,
    });
    return res.data;
  };

  const getCompaniesSaidasImports = async (
    key: QueryFunctionContext,
  ): Promise<IData<ISaidaImport[]>> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<IData<ISaidaImport[]>>('/api/v1/saidas/importacao/', {
      params: filters,
    });
    return res.data;
  };

  const getSaidasImportsTotais = async (key: QueryFunctionContext): Promise<any> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<any>('/api/v1/saidas/importacao/total/', {
      params: filters,
    });
    return res.data;
  };

  const getCompaniesSaidasXml = async (key: QueryFunctionContext): Promise<IData<ISaida[]>> => {
    const filters = (key.queryKey?.[1] as any) ?? {};
    const res = await api.get<IData<ISaida[]>>(`/api/v1/empresas/sieg/`, {
      params: filters,
    });
    return res.data;
  };

  const getTaxRegime = async (key: QueryFunctionContext): Promise<IData<ITaxRegime[]>> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<IData<ITaxRegime[]>>('/api/v1/regime_tributario/limite/', {
      params: filters,
    });
    return res.data;
  };
  const getQuebrasTaxRegimeTotais = async (key: QueryFunctionContext): Promise<any> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<any>('/api/v1/regime_tributario/limite/total/', {
      params: filters,
    });
    return res.data;
  };

  const getPartnerCompany = async (key: QueryFunctionContext): Promise<IData<IPartner[]>> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<IData<IPartner[]>>('/api/v1/regime_tributario/limite/socios/', {
      params: filters,
    });
    return res.data;
  };

  const getQuebrasAnalisesTotais = async (key: QueryFunctionContext): Promise<any> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<any>('/api/v1/empresas/impostos/total/', {
      params: filters,
    });
    return res.data;
  };

  const getNotasManualPosting = async (
    key: QueryFunctionContext,
  ): Promise<IData<INotasManualPosting[]>> => {
    const filters = key.queryKey?.[1] ?? {};
    const res = await api.get<IData<INotasManualPosting[]>>('/api/v1/saidas/importacao/notas/', {
      params: filters,
    });
    return res.data;
  };

  return {
    getCompanies,
    getSituationsTotals,
    getCompany,
    getQuebras,
    getQuebrasTotais,
    createCompany,
    updateCompany,
    deleteCompany,
    uploadCompanyFile,
    getQuebrasWithId,
    getCompaniesFilters,
    getCompaniesSaidas,
    getCompaniesSaidasImports,
    getSaidasImportsTotais,
    getCompaniesSaidasXml,
    getAPIXSiegTotais,
    getFaturamentoTotais,
    getTaxRegime,
    getPartnerCompany,
    getQuebrasTaxRegimeTotais,
    getQuebrasAnalisesTotais,
    getNotasManualPosting,
  };
};
